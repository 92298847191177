export default {
  methods: {
    validateRequestData (data) {
      if (!data.success || !data.data) {
        if (data.message) {
          this.errorMessage = data.message
        } else {
          this.errorMessage = 'Непредвиденная ошибка сервера'
        }
        return false
      }
      return true
    }
  }
}
