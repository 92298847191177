<template>
  <div class="pagination">
<!--    <v-container>-->
<!--      <v-row justify="start">-->
    <div>Отображено {{ showed }} из {{ countAll }}</div>
    <div>
      Выводить по&nbsp;
      <div>
        <select
          :value="maxCountOnPage"
          @change="changeMaxCount"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      &nbsp;записей
    </div>
    <v-pagination
      :value="page"
      :length="pageCount"
      :total-visible="7"
      @input="setPage"
      color="secondary"
    ></v-pagination>
<!--      </v-row>-->
<!--    </v-container>-->
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    page: {
      type: Number,
      default: 1
    },
    pageCount: {
      type: Number,
      required: true
    },
    countAll: {
      type: Number,
      required: true
    },
    maxCountOnPage: {
      type: String,
      required: true
    }
  },
  methods: {
    setPage (numberPage) {
      this.$emit('check-page', numberPage)
    },
    changeMaxCount ({ target }) {
      this.$emit('change-max-count', target.value)
    }
  },
  computed: {
    showed () {
      if (this.countAll === 0) {
        return 0
      }
      const startRecord = (this.page - 1) * this.maxCountOnPage + 1
      let finishRecord
      if (this.page * this.maxCountOnPage + 1 <= this.countAll) {
        finishRecord = this.page * this.maxCountOnPage
      } else {
        finishRecord = this.countAll
      }
      return `${startRecord} - ${finishRecord}`
    }
  }
}
</script>

<style lang="sass">

</style>
