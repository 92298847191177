const SRO_STATE_ENABLED = {
  id: 'enabled',
  title: 'Действует'
}
const SRO_STATE_DISABLED = {
  id: 'disabled',
  title: 'Исключено'
}
const SRO_STATE_WAIT = {
  id: 'wait',
  title: 'Замечания'
}

const MEMBER_STATUS_ENABLED = {
  id: '1'
}

const MEMBER_STATUS_DISABLED = {
  id: '2'
}

const FILTER_TYPE_EQ = 1
const FILTER_TYPE_LIKE = 2
const FILTER_TYPE_DATE = 3

const FILTER_SRO_STATE_VALUES = [
  SRO_STATE_ENABLED,
  SRO_STATE_DISABLED
]

export {
  SRO_STATE_WAIT,
  SRO_STATE_ENABLED,
  SRO_STATE_DISABLED,
  FILTER_TYPE_LIKE,
  FILTER_TYPE_EQ,
  FILTER_TYPE_DATE,
  FILTER_SRO_STATE_VALUES,
  MEMBER_STATUS_ENABLED,
  MEMBER_STATUS_DISABLED
}
