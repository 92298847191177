<template>
  <v-navigation-drawer
    right
    absolute
    temporary
    width="400"
    height="auto"
    :value="sortIsShow"
    @input="changeSortVisible"
  >
    <div
      class="filter"
      :key="Date.now()"
    >
      <div class="filter__title">
        <div class="chip__bn"
             style="min-width: 20px; background-color: white"
        ></div>
        Сортировка
        <v-icon class="chip__bn"
                style="min-width: 20px"
                @click="changeSortVisible(false)"
        >mdi-close
        </v-icon>
      </div>
      <div
        class="filter__content"
      >
        <div
          class="filter__row"
          v-for="sortItem in sortOptions"
          :key="'sortItem-' + sortItem.name"
        >
          <div class="filter__row_value no-border">
            <v-select
              :items="[{
                  value: 'ASC',
                  text: 'По возрастанию'
                }, {
                  value: 'DESC',
                  text: 'По убыванию'
                }]"
              :label="sortItem.title"
              clearable
              v-model="sort[sortItem.name]"
            ></v-select>
          </div>
        </div>
        <div class="filter__buttons">
          <v-btn
            class="btn"
            color="secondary"
            @click="clearSort"
          >Очистить
          </v-btn>
          <v-btn
            class="btn"
            color="secondary"
            @click.prevent="submitSort"
          >Применить
          </v-btn>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'SortForm',
  props: {
    sortOptions: {
      type: Array,
      required: true
    },
    sortIsShow: {
      type: Boolean,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sort: this.value
    }
  },
  methods: {
    changeSortVisible (value) {
      this.$emit('update:sort-is-show', value)
    },
    submitSort () {
      for (const key in this.sort) {
        if (!this.sort[key]) {
          delete this.sort[key]
        }
      }
      this.$emit('submit', Object.assign({}, this.sort))
    },
    clearSort () {
      this.$emit('submit', {})
    }
  },
  watch: {
    value: function () {
      this.sort = this.value
    }
  }
}
</script>
