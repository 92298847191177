<template>
  <div style="min-width: 300px">
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="dates"
        :width="310"
        style="min-width: 300px"
        @keydown.esc="modal = false"
      >
        <template v-slot:activator="{ on, attrs }">
          <div style="display: flex">
            <v-text-field
              :label="label"
              :value="dateRangeText"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
            <v-icon
              v-if="dates.length > 0"
              @click="clearDates"
              style="background-color: transparent"
            >mdi-close</v-icon>
          </div>
        </template>
        <v-date-picker
          v-model="dates"
          range
          scrollable
          :month-format="formatMonth"
          :weekday-format="formatDayOfWeek"
          :first-day-of-week="1"
          :title-date-format="formatArrayDates"
          :header-date-format="formatMonthYear"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="clearDates"
          > Очистить
          </v-btn>
          <v-btn
            text
            @click="modal = false"
          >
            Отмена
          </v-btn>
          <v-btn
            text
            @click="setDates"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
  </div>
</template>

<script>
import { formatDateShort, formatDayOfWeek, formatMonth, formatMonthYear } from '../../helpers/format'
export default {
  name: 'DatePicker',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dates: this.value,
      modal: false
    }
  },
  methods: {
    clearDates () {
      this.dates = []
      this.setDates()
    },
    setDates () {
      this.$refs.dialog.save(this.dates)
      this.$emit('input', [...this.dates].sort((a, b) => new Date(a) - new Date(b)))
    },
    formatMonth (date) {
      return formatMonth(new Date(date))
    },
    formatDayOfWeek (date) {
      return formatDayOfWeek(new Date(date))
    },
    formatArrayDates (dates) {
      return dates.map(el => new Date(el)).sort((a, b) => a - b).map(el => formatDateShort(el)).join(' - ')
    },
    formatMonthYear (date) {
      return formatMonthYear(new Date(date))
    }
  },
  computed: {
    dateRangeText () {
      return this.dates
        .map(el => new Date(el))
        .sort((a, b) => a - b)
        .map(el => formatDateShort(el)).join(' - ')
    }
  },
  watch: {
    value () {
      this.dates = this.value
    }
  }
}
</script>

<style scoped lang="sass">
  .v-icon:after
    background-color: transparent
</style>
