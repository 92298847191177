<template>
  <div class="d-flex flex-wrap">
    <div class="chip"
         v-for="chipItem in chips"
         :key="'chip-item-' + chipItem.name"
    >
      <div class="chip__content">
        <div class="chip__title">
          {{ chipItem.title }}: {{ chipItem.value }}
        </div>
        <v-icon class="chip__bn"
             @click="deleteChip(chipItem.name)"
        >mdi-close
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chips',
  props: {
    chips: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    deleteChip (name) {
      this.$emit('delete', name)
    }
  }
}
</script>

<style scoped>

</style>
