<template
>
  <v-navigation-drawer
    right
    absolute
    temporary
    width="400"
    height="auto"
    :value="filterIsShow"
    @input="changeFilterVisible"
  >
    <div
      class="filter"
    >
      <div class="filter__title">
        <div class="chip__bn"
             style="min-width: 20px; background-color: white"
        ></div>
        Фильтры
        <v-icon class="chip__bn"
                style="min-width: 20px"
                @click="close"
        >mdi-close
        </v-icon>
      </div>
      <div
        class="filter__content"
      >
        <div
          class="filter__row"
          v-for="filterItem in filterOptions"
          :key="'filterItem' + filterItem.name"
        >
          <div class="filter__row_value no-border"
               v-if="filterItem.type === FILTER_TYPE_EQ"
          >
            <v-select
              :items="getSelectItems(filterItem.values)"
              :label="filterItem.title"
              clearable
              v-model="innerFilters[filterItem.name]"
            ></v-select>
          </div>

          <div class="filter__row_value no-border"
               v-if="filterItem.type === FILTER_TYPE_LIKE"
          >
            <div>
              <v-text-field
                :label="filterItem.title"
                v-model="innerFilters[filterItem.name]"
                @keydown.enter="submitFilter"
              ></v-text-field>
            </div>
          </div>
          <div class="filter__row_value no-border"
               v-if="filterItem.type === FILTER_TYPE_DATE"
          >
            <date-picker
              :label="filterItem.title"
              v-model="innerFilters[filterItem.name]"
            />
          </div>
        </div>
        <div class="filter__buttons">
          <v-btn
            class="btn"
            color="secondary"
            @click="clearFilters"
          >Очистить
          </v-btn>
          <v-btn
            class="btn"
            color="secondary"
            @click.prevent="submitFilter"
          >Применить
          </v-btn>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { FILTER_TYPE_EQ, FILTER_TYPE_LIKE, FILTER_TYPE_DATE } from '../../helpers/dictionary'
import DatePicker from '../datepicker/DatePicker'

export default {
  name: 'FilterForm',
  components: {
    DatePicker
  },
  props: {
    filterOptions: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    filterIsShow: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      FILTER_TYPE_LIKE,
      FILTER_TYPE_EQ,
      FILTER_TYPE_DATE,
      innerFilters: Object.assign({}, this.filters)
    }
  },
  methods: {
    changeFilterVisible (value) {
      this.$emit('update:filter-is-show', value)
    },
    submitFilter () {
      for (const item in this.innerFilters) {
        if (!this.innerFilters[item]) {
          delete this.innerFilters[item]
        }
      }
      this.$emit('submit-filter', Object.assign({}, this.innerFilters))
    },
    clearFilters () {
      this.$emit('submit-filter', {})
    },
    setDates (dates, filterName) {
      this.innerFilters[filterName] = [...dates]
    },
    getSelectItems (values) {
      return values.map(el => ({
        text: el.title,
        value: el.id
      }))
    },
    close () {
      this.innerFilters = Object.assign({}, this.filters)
      this.changeFilterVisible(false)
    }
  },
  watch: {
    filters: function () {
      this.innerFilters = Object.assign({}, this.filters)
    }
  }
}
</script>
