import FilterForm from '../components/filter/FilterForm'
import SortForm from '../components/sort/SortForm'
import Spinner from '../components/spinner/Spinner'
import ErrorMessage from '../components/alerts/ErrorMessage'
import Pagination from '../components/pagination/Pagination'
import { FILTER_TYPE_DATE } from '../helpers/dictionary'
import { formatDateShort } from '../helpers/format'
import ValidationMixin from './ValidationMixin'
import ControlPanel from '../components/ControlPanel'

export default {
  data: () => ({
    page: 1,
    countPage: 0,
    count: 0,
    spinnerIsShow: false,
    chips: [],
    errorMessage: '',
    filterIsShow: false,
    sortIsShow: false,
    searchString: '',
    list: null,
    options: {
      filters: {},
      searchString: '',
      page: 1,
      pageCount: 2,
      sortBy: {}
    }
  }),
  mixins: [ValidationMixin],
  components: {
    FilterForm,
    SortForm,
    Spinner,
    ErrorMessage,
    Pagination,
    ControlPanel
  },
  methods: {
    async getList () {
      this.errorMessage = ''
      this.saveOptions(this.options)
      this.spinnerIsShow = true

      const requestData = await this.getDataList()
      this.spinnerIsShow = false

      if (!this.validateRequestData(requestData)) {
        return
      }

      this.list = requestData.data.data
      this.page = requestData.data.page
      this.count = requestData.data.count
      this.countPage = requestData.data.countPages
    },
    async sortBy (fieldName) {
      this.options.page = 1
      if (!this.options.sortBy[fieldName]) {
        this.options.sortBy[fieldName] = 'ASC'
      } else if (this.options.sortBy[fieldName] === 'ASC') {
        this.options.sortBy[fieldName] = 'DESC'
      } else {
        delete this.options.sortBy[fieldName]
      }
      await this.getList()
    },
    async submitFilter (filters) {
      this.options.filters = filters
      this.filtersToChips()
      this.options.page = 1
      this.filterIsShow = false
      await this.getList()
    },
    async submitSearch () {
      this.options.page = 1
      await this.getList()
    },
    async submitSort (sort) {
      this.options.page = 1
      this.options.sortBy = sort
      this.sortIsShow = false
      await this.getList()
    },
    async deleteFilter (filterName) {
      this.controlFilters()
      delete this.options.filters[filterName]
      await this.submitFilter(this.options.filters)
    },
    async setPage (numberPage) {
      this.controlFilters()
      this.options.page = numberPage
      await this.getList()
    },
    filtersToChips () {
      this.chips = []
      for (const filterName in this.options.filters) {
        if (!this.options.filters[filterName]) { continue }
        if (this.options.filters[filterName].length === 0) { continue }

        const filterOptionItem = this.filterOptions.find(el => el.name === filterName)
        const title = filterOptionItem.title
        this.chips.push({
          name: filterName,
          value: this.getValueChip(filterName),
          title
        })
      }
    },
    controlFilters () {
      for (const filterItem in this.options.filters) {
        if (!this.chips.find(el => el.name === filterItem)) {
          delete this.options.filters[filterItem]
        }
      }
      this.options.filters = Object.assign({}, this.options.filters)
    },
    getValueChip (filterName) {
      const filterOptionItem = this.filterOptions.find(el => el.name === filterName)
      if (filterOptionItem.type === FILTER_TYPE_DATE) {
        return this.options.filters[filterName].map(el => formatDateShort(new Date(el))).join(' - ')
      }

      if (filterOptionItem.values) {
        return filterOptionItem.values.find(el => el.id === this.options.filters[filterName]).title
      }

      return this.options.filters[filterName]
    },
    async changeMaxCount (count) {
      this.options.pageCount = count
      this.options.page = 1
      await this.getList()
    }
  },
  async created () {
    this.options = this.loadOptions()
    await this.getDictionaries()
    this.filtersToChips()
    await this.getList()
  }
}
