<template>
  <div>
    <div  class="control-panel">
      <div class="mr-0 ml-auto d-flex">
        <slot name="begin-buttons"></slot>
        <v-icon
          class="filter-icon"
          @click="$emit('show-filter')"
        >mdi-filter</v-icon>
        <v-icon
          class="sort-icon"
          @click="$emit('show-sort')"
        >mdi-sort</v-icon>
        <slot name="end-buttons"></slot>
      </div>
      <div class="search">
        <v-text-field
          :value="searchString"
          @input="updateSearchString"
          append-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          @click:append="$emit('submit-search')"
          @click:clear="clearSearchString"
          @keydown.enter="$emit('submit-search')"
          @keydown.esc="clearSearchString"
          :label="labelSearch"
        />
      </div>
    </div>
    <div class="d-flex">
      <chips-component :chips="chips" @delete="deleteFilter"/>
    </div>
  </div>
</template>

<script>
import ChipsComponent from './chip/Chips'
export default {
  name: 'ControlPanel',
  components: {
    ChipsComponent
  },
  props: {
    chips: {
      type: Array,
      default: () => []
    },
    searchString: {
      type: String,
      default: ''
    },
    labelSearch: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      searchStringValue: this.searchString
    }
  },
  methods: {
    updateSearchString (value) {
      this.$emit('update:search-string', value)
    },
    deleteFilter (filter) {
      this.$emit('delete-filter', filter)
    },
    clearSearchString () {
      this.updateSearchString('')
      this.$emit('submit-search')
    }
  },
  watch: {
    searchString (value) {
      this.searchStringValue = value
    }
  }
}
</script>

<style scoped>

</style>
