import { formatDate, formatDateShort } from '../helpers/format'
import SortIcon from '../components/SortIcon'

export default {
  components: {
    SortIcon
  },
  props: {
    searchString: {
      type: String,
      default: ''
    },
    sortBy: {
      type: Object,
      default: () => {}
    },
    list: {
      type: Array,
      required: true
    }
  },
  methods: {
    highlight (string) {
      if (!string) return ''
      if (!this.searchString) return string

      return string
        .replace(
          new RegExp(this.searchString, 'ig'),
          `<strong
                style="background-color: rgba(210, 220, 255, 0.35)"
            >${this.searchString.toUpperCase()}</strong>`
        )
    },
    formatDate (dateString) {
      if (!dateString) {
        return
      }
      return formatDate(new Date(dateString))
    },
    formatDateShort (dateString) {
      if (!dateString) {
        return
      }
      return formatDateShort(new Date(dateString))
    },
    sort (fieldName) {
      this.$emit('sort', fieldName)
    }
  }
}
