<template>
  <v-icon
    class="icon-sort"
    color="primary"
  >
    {{ sortIcon }}
  </v-icon>
</template>

<script>
export default {
  name: 'SortIcon',
  props: {
    sort: {
      type: String,
      default: null
    }
  },
  computed: {
    sortIcon () {
      if (!this.sort) { return }
      if (this.sort === 'ASC') { return 'mdi-sort-ascending' }
      return 'mdi-sort-descending'
    }
  }
}
</script>

<style scoped>

</style>
